import React from 'react';

const LocationRepeaters = props => {
    if (props.activeLoc === 'usa') {
        return props.dataUsa.map((location, i) => (
            <div className="locationwrap" key={i}>
                <img
                    src={
                        location.location_image_usa.localFile.childImageSharp
                            .fluid.src
                    }
                    alt="location"
                />
                <h3
                    dangerouslySetInnerHTML={{
                        __html: location.location_name,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: location.location_info,
                    }}
                />
                <div className="greenlinksloc">
                    {location.view_on_map_url ? (
                        <h4>
                            <a
                                href={location.view_on_map_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW ON MAP
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.contact_us_url ? (
                        <h4>
                            <a
                                href={location.contact_us_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CONTACT US
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.view_photos_url ? (
                        <h4>
                            <a
                                href={location.view_photos_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW PHOTOS
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}
                </div>
                {location.visit_website_url ? (
                    <a
                        href={location.visit_website_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="wrtsbtn yellowbtn"
                    >
                        VISIT WEBSITE
                    </a>
                ) : (
                    ''
                )}
            </div>
        ));
    }
    if (props.activeLoc === 'int') {
        return props.dataInt.map((location, i) => (
            <div className="locationwrap" key={i}>
                <img
                    src={
                        location.location_image_int.localFile.childImageSharp
                            .fluid.src
                    }
                    alt="location"
                />
                <h3
                    dangerouslySetInnerHTML={{
                        __html: location.location_name,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: location.location_info,
                    }}
                />
                <div className="greenlinksloc">
                    {location.view_on_map_url ? (
                        <h4>
                            <a
                                href={location.view_on_map_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW ON MAP
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.contact_us_url ? (
                        <h4>
                            <a
                                href={location.contact_us_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CONTACT US
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.view_photos_url ? (
                        <h4>
                            <a
                                href={location.view_photos_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW PHOTOS
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}
                </div>
                {location.visit_website_url ? (
                    <a
                        href={location.visit_website_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="wrtsbtn yellowbtn"
                    >
                        VISIT WEBSITE
                    </a>
                ) : (
                    ''
                )}
            </div>
        ));
    }
    if (props.activeLoc === 'usa-soon') {
        return props.dataUsaCs.map((location, i) => (
            <div className="locationwrap" key={i}>
                <img
                    src={
                        location.location_image_c_usa.localFile.childImageSharp
                            .fluid.src
                    }
                    alt="location"
                />
                <h3
                    dangerouslySetInnerHTML={{
                        __html: location.location_name,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: location.location_info,
                    }}
                />
                <div className="greenlinksloc">
                    {location.view_on_map_url ? (
                        <h4>
                            <a
                                href={location.view_on_map_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW ON MAP
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.contact_us_url ? (
                        <h4>
                            <a
                                href={location.contact_us_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CONTACT US
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.view_photos_url ? (
                        <h4>
                            <a
                                href={location.view_photos_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW PHOTOS
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}
                </div>
                {location.visit_website_url ? (
                    <a
                        href={location.visit_website_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="wrtsbtn yellowbtn"
                    >
                        VISIT WEBSITE
                    </a>
                ) : (
                    ''
                )}
            </div>
        ));
    }
    if (props.activeLoc === 'int-soon') {
        return props.dataIntCs.map((location, i) => (
            <div className="locationwrap" key={i}>
                <img
                    src={
                        location.location_image_c_int.localFile.childImageSharp
                            .fluid.src
                    }
                    alt="location"
                />
                <h3
                    dangerouslySetInnerHTML={{
                        __html: location.location_name,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: location.location_info,
                    }}
                />
                <div className="greenlinksloc">
                    {location.view_on_map_url ? (
                        <h4>
                            <a
                                href={location.view_on_map_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW ON MAP
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.contact_us_url ? (
                        <h4>
                            <a
                                href={location.contact_us_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CONTACT US
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}

                    {location.view_photos_url ? (
                        <h4>
                            <a
                                href={location.view_photos_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                VIEW PHOTOS
                            </a>
                        </h4>
                    ) : (
                        ''
                    )}
                </div>
                {location.visit_website_url ? (
                    <a
                        href={location.visit_website_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="wrtsbtn yellowbtn"
                    >
                        VISIT WEBSITE
                    </a>
                ) : (
                    ''
                )}
            </div>
        ));
    }
};

export default LocationRepeaters;
